// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/";
const ROOTS_APP_INTEGRADOR = "/integrador";
const ROOTS_APP_SOCIAL_MEDIA = "/midias-sociais";
const ROOTS_APP_GESTAO = "/gestao";
const ROOTS_APPS_CONSULTAS = "/consultas";
const ROOTS_APPS_PERFIL_CLICK = "/perfil-click";
const ROOTS_APPS_SITE = "/meu-site";
const ROOTS_APPS_CONCILIADOR = "/conciliador";
const ROOTS_APPS_USUARIOS = "/pagamento";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATHS = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, "user"),
    new: path(ROOTS_DASHBOARD, "user/new"),
    list: path(ROOTS_DASHBOARD, "user/list"),
    cards: path(ROOTS_DASHBOARD, "user/cards"),
    profile: path(ROOTS_DASHBOARD, "user/profile"),
    account: path(ROOTS_DASHBOARD, "user/account"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `user/reece-chung/edit`),
  },
  suporte: "https://wa.me/5511972237148",
  dashboard: ROOTS_DASHBOARD,
  apps: {
    socialMedias: {
      facebook: {
        root: path(ROOTS_APP_SOCIAL_MEDIA, "/facebook"),
        dashboard: () =>
          path(PATHS.apps.socialMedias.facebook.root, `?step=dashboard`),
        calendar: () =>
          path(PATHS.apps.socialMedias.facebook.root, `?step=calendario`),
        scheduling: () =>
          path(PATHS.apps.socialMedias.facebook.root, `?step=agendamento`),
      },
    },
    integrador: {
      tags: {
        root: path(ROOTS_APP_INTEGRADOR, "/tags"),
        edit: (id: string) =>
          path(PATHS.apps.integrador.tags.root, `/${id}/edit`),
        new: () => path(PATHS.apps.integrador.tags.root, `/new`),
      },
      credenciais: {
        root: path(ROOTS_APP_INTEGRADOR, "/credenciais"),
        rootForVendor: (vendorId: string, errorMessage = "") =>
          path(
            ROOTS_APP_INTEGRADOR,
            `/credenciais?vendorId=${vendorId}&errorMessage=${errorMessage}`
          ),
        edit: (id: string) =>
          path(PATHS.apps.integrador.credenciais.root, `/${id}/edit`),
        new: (vendorId: string, portalId: string) =>
          path(
            PATHS.apps.integrador.credenciais.root,
            `/new?vendorId=${vendorId}&portalId=${portalId}`
          ),
      },
      estoque: {
        root: path(ROOTS_APP_INTEGRADOR, "/estoque"),
        ativos: path(ROOTS_APP_INTEGRADOR, "/estoque/1"), //status ativo
        desativados: path(ROOTS_APP_INTEGRADOR, "/estoque/2"), //status inativo
        new: (type: any, condition: any) =>
          path(
            PATHS.apps.integrador.estoque.root,
            `/new?vehicleType=${type}&vehicleCondition=${condition}`
          ),
        edit: ({
          vId,
          isPublished,
          vehicleType,
          hasGestao,
        }: {
          vId: string;
          isPublished: boolean;
          vehicleType: number;
          hasGestao: boolean;
        }) =>
          hasGestao
            ? path(
                PATHS.apps.gestao.estoque.root,
                `/vehicle?isPublished=${isPublished}&step=dados&vehicleType=${vehicleType}&vehicleId=${vId}`
              )
            : path(
                PATHS.apps.integrador.estoque.root,
                `/${vId}/edit?isPublished=${isPublished}&vehicleType=${vehicleType}`
              ),
      },
      config: {
        root: path(ROOTS_APP_INTEGRADOR, "/config"),
      },
      aderir: path(ROOTS_APP_INTEGRADOR, "/aderir"),
    },
    gestao: {
      pessoas: {
        root: path(ROOTS_APP_GESTAO, "/pessoas/1"),
        new: path(ROOTS_APP_GESTAO, "/pessoas/new"),
      },
      financeiro: {
        root: path(ROOTS_APP_GESTAO, "/financeiro/movimentacao"),
      },
      estoque: {
        root: path(ROOTS_APP_GESTAO, "/estoque"),
        ativos: path(ROOTS_APP_GESTAO, "/estoque/1"),
        desativados: path(ROOTS_APP_GESTAO, "/estoque/2"),
        vendidos: path(ROOTS_APP_GESTAO, "/estoque/3"),
        transito: path(ROOTS_APP_GESTAO, "/estoque/4"),
        tags: {
          root: path(ROOTS_APP_GESTAO, "/tags"),
          edit: (id: string) =>
            path(PATHS.apps.gestao.estoque.tags.root, `/${id}/edit`),
          new: () => path(PATHS.apps.gestao.estoque.tags.root, `/new`),
        },
        new: () => path(PATHS.apps.gestao.estoque.root, `/vehicle`),
        edit: ({
          vId,
          isPublished,
          vehicleType,
        }: {
          vId: string;
          isPublished: boolean;
          vehicleType: number;
        }) =>
          path(
            PATHS.apps.gestao.estoque.root,
            `/vehicle?isPublished=${isPublished}&step=dados&vehicleType=${vehicleType}&vehicleId=${vId}`
          ),
      },
      usuarios: {
        root: path(ROOTS_APP_GESTAO, "/usuarios"),
      },
      permissoes: {
        root: path(ROOTS_APP_GESTAO, "/permissoes"),
      },
    },
    consultas: {
      aderir: path(ROOTS_APPS_CONSULTAS, "/aderir"),
      precoVeiculos: path(ROOTS_APPS_CONSULTAS, "/tabela-fipe"),
    },
    perfilClick: {
      root: ROOTS_APPS_PERFIL_CLICK,
    },
    site: {
      gestor: path(ROOTS_APPS_SITE, "/gestor"),
      aderir: path(ROOTS_APPS_SITE, "/aderir"),
      conteudo: path(ROOTS_APPS_SITE, "/conteudo"),
      adminCreate: () => path(PATHS.apps.site.gestor, `/admin-create`),
      adminVendorCreate: () => path(PATHS.apps.site.gestor, `/vendor-create`),
    },
    conciliador: {
      root: ROOTS_APPS_CONCILIADOR,
      edit: (cnpj: string, id: string) =>
        path(ROOTS_APPS_CONCILIADOR, `/${id}/edit?cnpj=${cnpj}`),
      veiculos: path(ROOTS_APPS_CONCILIADOR, "/veiculos"),
      eventos: path(ROOTS_APPS_CONCILIADOR, "/eventos"),
    },
    usuarios: {
      root: path(ROOTS_APPS_USUARIOS, `?step=painel`),
      // usuarios: {
      //   root: path(ROOTS_APPS_USUARIOS, "/usuarios"),
      // },
    },
  },
};

export const PATH_DOCS = "";
