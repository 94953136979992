import { Auth0Provider } from "@auth0/auth0-react";
import {
  CollapseDrawerProvider,
  getSettings,
  MotionLazyContainer,
  ProgressBar,
  SettingsProvider,
  SettingsValueProps,
  ThemeMode,
  ThemeProvider,
} from "@sync/core";
import "../styles/globals.css";
import {
  QueryClient,
  QueryClientProvider,
  ReactQueryDevtools,
} from "@sync/utils";
import cookie from "cookie";
import { NextPage } from "next";
import App, { AppContext, AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "simplebar/src/simplebar.css";
import NonSSRWrapper from "src/components/common/NonSSRWrapper";
import { AUTH0_API, SYNC_CONSTS } from "../config";

import CustomThemeProvider from "src/context/theme-context";
import { getCookie } from "cookies-next";
import { ThemeTypes } from "src/store/themeStore/props";
import NotificationToast from "src/components/notifications/toasty";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Router } from "next/router";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  settings: SettingsValueProps;
  Component: NextPageWithLayout;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 2,
      cacheTime: 1000 * 60 * 15,
    },
  },
});
export default function MyApp(props: MyAppProps) {
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page) => page);
  const theme = getCookie("@sync-theme") ?? ThemeTypes.Light;

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
      person_profiles: "identified_only",

      loaded: (posthog) => {
        // if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });

    const handleRouteChange = () => posthog?.capture("$pageview");

    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    // @ts-ignore
    <NonSSRWrapper>
      {typeof window != "undefined" && (
        <QueryClientProvider client={queryClient}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <Auth0Provider
            domain={AUTH0_API.domain as string}
            clientId={AUTH0_API.clientId as string}
            redirectUri={SYNC_CONSTS.baseAddress}
            useCookiesForTransactions={true}
          >
            <SettingsProvider
              defaultSettings={{
                themeColorPresets: "blue",
                themeContrast: "default",
                themeDirection: "ltr",
                themeLayout: "horizontal",
                themeMode: theme as ThemeMode,
                themeStretch: false,
              }}
            >
              <ThemeProvider>
                <CustomThemeProvider>
                  <PostHogProvider client={posthog}>
                    <CollapseDrawerProvider>
                      <CustomThemeProvider>
                        <MotionLazyContainer>
                          <NotificationToast />
                          <ProgressBar />
                          {/* @ts-ignore */}
                          {getLayout(<Component {...pageProps} />)}
                        </MotionLazyContainer>
                      </CustomThemeProvider>
                    </CollapseDrawerProvider>
                  </PostHogProvider>
                </CustomThemeProvider>
              </ThemeProvider>
            </SettingsProvider>
          </Auth0Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      )}
    </NonSSRWrapper>
  );
}

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || "" : document.cookie
  );

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};
