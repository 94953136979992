import { SettingsValueProps } from "@sync/core";
import { PATHS } from "./routes/paths";
import {
  AdvertiseState,
  AdvertiseStatus,
  Portals,
} from "@sync/utils/src/integrador/api";

//#region SYNC
export const SYNC_CONSTS = {
  syncMvcLegacyAddres: process.env.APPSYNC_LEGACY_ADDRESS,
  integradorLegacyAddres: process.env.INTEGRADOR_LEGACY_ADDRESS,
  appPrefix: "sync",
  baseAddress: process.env.BASE_ADDRESS,
  apiBaseAddress: process.env.API_BASE_ADDRESS,
  authTokenSufix: "-auth-token",
  skUserSufix: "-session-user",
  skUserPermissionSufix: "-session-user-permission",
  skUserVendorSufix: "-session-user-vendor",
  skGrantedPermissionsSufix: "-session-user-granted-permissions",
  subscriptionsSufix: "-subscriptions",
  skUserAppsSufix: "-session-user-apps",
  domainVerificationKey: process.env.domainVerificationKey,
  appUniqueName: {
    //nomes de acordo com APP SYNC LEGADO
    sync: "app-sync",
    syncRepasseLojista: "megarepasse-lojista",
    syncRepasseConcessionaria: "megarepasse-concessionaria",
    syncAnalytics: "sync-analytics",
    syncConciliador: "sync-conciliador",
    syncIntegrador: "flowbits-integrador",
    syncGestao: "sync-gestao",
    syncCMS: "sync-site",
    syncMotorMe: "flowbits-motor-me",
    syncPerfilClick: "sync-perfil-click",
    syncConsultaTabela: "sync-consulta-preco",
  },
};
//#endregion

//#region FACEBOOK
export const FACEBOOK_CONSTS = {
  appId: process.env.FACEBOOK_SYNC_WEBSITES_APP_ID,
  appSecret: process.env.FACEBOOK_SYNC_WEBSITES_APP_SECRET,
  callbackServerUrl:
    "{baseAddress}/api/facebook/exchange-code-for-token?page={page}",
  // callbackServerUrl:
  //   "{baseAddress}/api/facebook/exchange-code-for-token?id={siteId}&siteName={siteName}&subdomain={subdomain}",
  callbackClientUrl: "{baseAddress}/midias-sociais/facebook/{page}",
  // callbackClientUrl:
  //   "{baseAddress}/meu-site/gestor/site/integracoes?id={siteId}&siteName={siteName}&subdomain={subdomain}",
  scopes: "pages_manage_posts,pages_read_engagement",
};
//#endregion

//#region INTEGRADOR
const mercadoLivre = {
  credentialClientId: process.env.INTEGRADOR_ML_CLIENTID,
  credentialClientSecre: process.env.INTEGRADOR_ML_CLIENTSECRET,
  authorizationCodeBaseAddress: "https://auth.mercadolivre.com.br/",
  getAuthorizationCodeRedirectUri:
    process.env.BASE_ADDRESS + "integrador/oauth/mercado-livre", //setar call back aqui                                                                                                                                          "&state={state}";
};

const olx = {
  credentialClientId: "23bc0187a6a853dba169bbe7f58a5d91a04c2e0c",
  authBaseAddress: "https://auth.olx.com.br/",
  getAutorizationCodeIntegradorRedirectUri:
    "https://9533-2804-7f0-bdcd-79b-919d-911a-4455-96f1.sa.ngrok.io/integrador/oauth/olx", //temp porque mandei errado para o suporte da olx, prod = integrador/oauth/olx
};

type NameId = {
  id: number;
  name: string;
};

type PortalsType = {
  [x: string]: NameId;
};

const portals: PortalsType = {
  webmotors: {
    id: 1,
    name: Portals.WEB_MOTORS_PORTAL_NAME,
  },
  autoline: {
    id: 2,
    name: Portals.AUTOLINE_PORTAL_NAME,
  },
  comprecar: {
    id: 3,
    name: Portals.COMPRE_CAR_PORTAL_NAME,
  },
  facebook: {
    id: 4,
    name: Portals.FACEBOOK_PORTAL_NAME,
  },
  iCarros: {
    id: 5,
    name: Portals.I_CARROS_PORTAL_NAME,
  },
  mercadoLivre: {
    id: 6,
    name: Portals.MERCADO_LIVRE_PORTAL_NAME,
  },
  meuCarroNovo: {
    id: 7,
    name: Portals.MEU_CARRO_NOVO_PORTAL_NAME,
  },
  mobiauto: {
    id: 8,
    name: Portals.MOBI_AUTO_PORTAL_NAME,
  },
  olx: {
    id: 9,
    name: Portals.OLX_PORTAL_NAME,
  },
  resources: {
    id: 10,
    name: Portals.RESOURCES_PORTAL_NAME,
  },
  motorMe: {
    id: 11,
    name: Portals.MOTOR_ME_PORTAL_NAME,
  },
  usadosbr: {
    id: 12,
    name: Portals.USADOSBR_PORTAL_NAME,
  },
  syncCMS: {
    id: 13,
    name: Portals.SYNC_CMS_PORTAL_NAME,
  },
  carroSP: {
    id: 14,
    name: Portals.CARRO_SP,
  },
  chavesNaMao: {
    id: 15,
    name: Portals.CHAVES_NA_MAO,
  },
  platforms: {
    id: 16,
    name: Portals.PLATFORMS,
  },
  soCarrao: {
    id: 17,
    name: Portals.SO_CARRAO,
  },
  repasseAutos: {
    id: 18,
    name: Portals.REPASSE_AUTOS,
  },
  salaoDoCarro: {
    id: 21,
    name: Portals.SALAO_DO_CARRO,
  },
  clickAutos: {
    id: 22,
    name: Portals.CLICK_AUTOS,
  },
  netMotors: {
    id: 24,
    name: Portals.NET_MOTORS,
  },
  autoShopping: {
    id: 25,
    name: Portals.AUTO_SHOPPING,
  },
};

const credentialsWarnings = {
  onDelete: `Ao remover credenciais seus veículos serão apagados do portal <b>{portalName}</b>!  Esse processo pode levar até 2 minutos. <br /><br />Em caso de dúvidas entre em <a href="{suportRef}" class="suporte-btn">contato com o suporte</a>. <br /><br />`,
  onCreate:
    `Ao adicionar credenciais seus veículos serão apagados do portal <b>{portalName}</b>! Esse processo pode levar até 2 minutos. <br /><br />` +
    `Em caso de dúvidas entre em <a target="_blank" href="{suportRef}" class="suporte-btn">contato com o suporte</a>. <br /><br />`,
  onCreateOlx:
    "O integrador não sincroniza anúncios já criados na OLX. Você pode mantê-los ou excluí-los diretamente no portal OLX. <br /><br />" +
    "<b>Fique atento!</b> A OLX, atualmente, cobra por quantidade de publicações e não por quantidade de anúncios. <br /><br />" +
    `Em caso de dúvidas entre em <a target="_blank" href="{suportRef}" class="suporte-btn">contato com o suporte</a>. <br /><br />` +
    "<b>Ao continuar você será redirecionado à OLX.</b>  <br /><br />",
  onCreateMercadoLivre:
    "Ao adicionar credenciais seus veículos serão apagados do portal <b>Mercado Livre</b>! Esse processo pode levar até 2 minutos. <br /><br />" +
    `Em caso de dúvidas entre em <a target="_blank" href="{suportRef}" class="suporte-btn">contato com o suporte</a>. <br /><br />` +
    "<b>Ao continuar você será redirecionado ao Mercado Livre.</b>  <br />",
};

// const shippingItemStatus = {
//   falha: ShippingItemStatus.FALHA,
//   sucesso: ShippingItemStatus.SUCESSO,
//   processando: ShippingItemStatus.PROCESSANDO,
// };

const advertiseStatus = {
  published: AdvertiseStatus.PUBLISHED,
  notPublished: AdvertiseStatus.NOT_PUBLISHED,
  publishedWithNotes: AdvertiseStatus.ERROR,
  empty: AdvertiseStatus.NOT_PUBLISHED,
};

const advertiseState = {
  undefined: AdvertiseState.UNDEFINED,
  inventory: AdvertiseState.INVENTORY,
  deleted: AdvertiseState.DELETED,
  transito: AdvertiseState.TRANSITO,
  sold: AdvertiseState.SOLD,
};

const inventoryPageStatusFilter = {
  all: "all",
  pendding: "pendding",
  requiresAttention: "requiresAttention",
  partiallyPublished: "partiallyPublished",
};

type vehicleCategories = {
  [x: string]: NameId;
};

const vehicleCategory: vehicleCategories = {
  indefinido: {
    id: 0,
    name: "Indefinido",
  },
  hatch: {
    id: 1,
    name: "Hatch",
  },
  sedan: {
    id: 2,
    name: "Sedan",
  },
  picape: {
    id: 3,
    name: "Picape",
  },
  suv: {
    id: 4,
    name: "SUV",
  },
  monoVolume: {
    id: 5,
    name: "MonoVolume",
  },
  perua: {
    id: 6,
    name: "Perua",
  },
  van: {
    id: 7,
    name: "Van",
  },
  conversivelCupe: {
    id: 8,
    name: "Conversível Cupê",
  },
  hibridoEletrico: {
    id: 9,
    name: "Híbrido Elétrico",
  },
};

type vehicleTypes = {
  [x: string]: NameId;
};

const vehicleType: vehicleTypes = {
  nenhum: {
    id: 0,
    name: "Nenhum",
  },
  carro: {
    id: 1,
    name: "Carro",
  },
  motocicleta: {
    id: 2,
    name: "Motocicleta",
  },
  caminhao: {
    id: 3,
    name: "Caminhão",
  },
};

type vehicleConditions = {
  [x: string]: NameId;
};

const vehicleCondition: vehicleConditions = {
  novo: {
    id: 1,
    name: "Novo",
  },
  usado: {
    id: 2,
    name: "Usado",
  },
};

export const INTEGRADOR_CONSTS = {
  apiBaseAddress: process.env.INTEGRADOR_API_BASE_ADDRESS,
  uiBaseAddress: process.env.INTEGRADOR_UI_BASE_ADDRESS,
  portalsLogosAddress: process.env.INTEGRADOR_PORTALS_LOGOS_ADDRESS,
  portalsS3LogosAddress: process.env.INTEGRADOR_S3_PORTALS_LOGOS_ADDRESS,
  cdnBaseAddress: process.env.CDN_BASE_ADDRESS,
  tunel: process.env.BASE_ADDRESS,
  appPrefix: "fbi",
  suportRef: "https://wa.me/5511972237148",
  suportRefWithMessage: "https://wa.me/?phone=5511972237148&text=",
  portals,
  credentialsWarnings,
  // shippingItemStatus,
  advertiseStatus,
  advertiseState,
  inventoryPageStatusFilter,
  vehicleCategory,
  vehicleType,
  vehicleCondition,
  mercadoLivreGetAutorizationCodeRedirectUrl: `${mercadoLivre.authorizationCodeBaseAddress}authorization?response_type=code&client_id=${mercadoLivre.credentialClientId}&redirect_uri=${mercadoLivre.getAuthorizationCodeRedirectUri}&state={state}`,
  olxGetAutorizationCodeRedirectUrl: `${olx.authBaseAddress}oauth?scope=basic_user_info%20autoupload&state={state}&redirect_uri=${olx.getAutorizationCodeIntegradorRedirectUri}&response_type=code&client_id=${olx.credentialClientId}`,
};

//#endregion

//#region SYNC CMS (squidex)

export const SYNC_CMS_CONSTS = {
  baseAddress: process.env.SYNC_CMS_BASE_ADDRESS,
  slug: "{make}-{model}-{modelYear}:{vehicleAdId}",
  //previewLink: "https://{vendorSite}integrador-redirect?slug={slug}",
  //site do up estava com protocolo (não deve estar), então tirei o protocolo, mas precisa voltar
  previewLink: "{vendorSite}integrador-redirect?slug={slug}",
};

//#endregion

//#region SYNC CONCILIADOR

export const SYNC_CONCILIADOR_CONSTS = {
  apiBaseAddress: process.env.conciliadorApiBaseAddress,
  apiToken: process.env.conciliadorApiToken,
};

//#endregion

//#region MINIMAL UI
export const AUTH0_API = {
  clientId: process.env.AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN,
};

export const PATH_AFTER_LOGIN = PATHS.root;

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const cookiesExpires = 3;

export const cookiesKey = {
  themeMode: "themeMode",
  themeLayout: "themeLayout",
  themeStretch: "themeStretch",
  themeContrast: "themeContrast",
  themeDirection: "themeDirection",
  themeColorPresets: "themeColorPresets",
};

export const defaultSettings: SettingsValueProps = {
  themeMode: "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: "horizontal",
  themeColorPresets: "default",
  themeStretch: false,
};
//#endregion

//#region PLATFORMS

export const PLATFORMS_CONSTS = {
  baseAddress: process.env.PLATFORMS_BASE_ADDRESS,
  loginEndpoint: process.env.PLATFORMS_LOGIN_ENDPOINT,
  checkSessionEndpoint: process.env.PLATFORMS_CHECK_SESSION_ENDPOINT,
  siteEndpoint: process.env.PLATFORMS_SITE_ENDPOINT,
  contentEndPoint: process.env.PLATFORMS_CONTENT_ENDPOINT,
  contentRevalidateTag: process.env.PLATFORMS_REVALIDATETAG_ENDPOINT,
  settingEndpoint: process.env.PLATFORMS_SETTING_ENDPOINT,
  domainEndpoint: process.env.PLATFORMS_DOMAIN_ENDPOINT,
};

//#endregion

//#region HEADLESS CMS

export const CMS_CONSTS = {
  appPrefix: "cms",
};

//#endregion
